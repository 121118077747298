import React from "react";
import Carousel from "../../components/carousel";
import { Icon } from "@iconify/react";
import ClientSection from "../../components/clientsection";
import { Link, useLocation } from "react-router-dom";
import ClientReviewSlider from "../../components/clientReviewSlider";
import { useSpring, animated, to } from "react-spring";

function Home() {
  const animationProps = useSpring({
    from: {
      opacity: 0,
      transform: "translateY(20px)",
    },
    to: {
      opacity: 1,
      transform: "translateY(0)",
    },
    config: {
      tension: 200,
      friction: 20,
    },
  });

  const location = useLocation();

  return (
    <div className="Home w-full scroll-smooth   relative  bg-white opacity-100 p-0">
      <div className="w-full  ">
        <Carousel />
      </div>

      <div className="flex px-10 w-full">
        <div className="flex flex-wrap">
          <div className=" relative ">
            <div className="pt-[50px] sm:mx-auto ">
              <img className="circleimg" src="homepage/developing.jpg" />
            </div>
            <div className="p-0 ">
              {/* <div className="Ellipse6 h-[493px] top-0 right-0  absolute bg-lime-500 bg-opacity-40 rounded-full blur-[300px]" /> */}
            </div>
          </div>

          <div className="flex-1 flex-wrap md:pl-[100px] pt-[80px] ">
            <div className="heading-title    ">Welcome To AWG </div>
            <div className="mb-5 relative text-neutral-500 text-sm font-Semibold font-['Dubai'] leading-snug hover:-translate-y-1 transition ease-in-out delay-100">
              Where Nature and Design Collide! At AWG, we think that outdoor
              areas can be transformed into living works of art. Our love of
              landscaping, combined with our dedication to environmental
              sustainability, distinguishes us as a renowned landscaping firm.
              We urge you to explore our website and discover the world of
              gardening options that await you.
              <br />
              <br />
              We're more than just landscapers; we're artists that adore the
              vast outdoors. Our crew is driven by a desire to transform your
              outside space into a work of art. We don't just work with plants;
              we also work with your hopes and goals. We can help you with a
              comprehensive garden makeover, ongoing upkeep, or hardscaping
              options. Our services include everything from garden design to
              installation, irrigation, and ongoing upkeep.
            </div>
            <Link to="/aboutus">
              <button type="button" className="home-button  ">
                Learn More
              </button>
            </Link>
            <div className="smallimg3 flex justify-between item-centre">
              <div className="flex space-x-7 pt-5">
                <div className="md:flex-1 flex-shrink">
                  <img
                    className=" small-img"
                    src="homepage/pexels-huy-phan-3076899.jpg"
                  />
                </div>

                <div className="md:flex-2">
                  <img className=" small-img  " src="homepage/aboutussub.jpg" />
                </div>

                <div className="md:flex-3">
                  <img className=" small-img" src="homepage/GC.jpg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="heading-title text-center pt-8 mt-10 md:text-center md:text-4Xl">
        What We Do
      </div>

      {/* landscape */}

      <div className="flex px-10 w-full">
        <div className="flex flex-wrap pt-[50px] items-center flex md:flex-row flex-col  items-center">
          {/* Landscape */}
          <div className="flex-2 flex-wrap md:flex-1 md:px-20">
            <div className="heading-title text-center pt-10">Landscape</div>
            <div className="heading-title text-center text-[36px]">
              Design, Construction & Maintenance
            </div>
            <div className="text-content">
              Our passion for plants as well as a holistic approach to
              landscaping is what energizes our teams to create a balanced and
              beautiful environment that injects both color and life into
              private, public and commercial spheres of the UAE.
              <br></br>
              Elevate your outdoor space with our expert landscaping services.
              Transforming ordinary yards into lush havens, our dedicated team
              crafts breathtaking landscapes. From meticulous lawn care to
              vibrant garden designs, we specialize in creating sustainable,
              awe-inspiring outdoor environments. Trust us for unparalleled
              expertise in landscaping that turns your vision into vibrant
              reality.
            </div>
            <div className="flex mt-5 justify-center items-center">
              <Link to="/services">
                <button type="button" className="home-button  ">
                  Learn More
                </button>
              </Link>
            </div>
          </div>
          <div className=" md:pl-0 flex flex-wrap md:flex-2 mt-10 md:mt-0 relative justify-center items-center">
            {/* <div className=''>
              <img className="leaf design w-[590.60px] h-[425.15px]  absolute rounded-[400px]" src="leafborder.png" />
            </div> */}
            <div className="pr-5">
              <img className="circleimg" src="homepage/homemainaboutus.jpg" />
            </div>
            {/* <div className="Ellipse6 w-[400px] h-[400px] left-0 top-0 absolute bg-lime-500 bg-opacity-40 rounded-full blur-[300px]" /> */}
          </div>
        </div>
      </div>

      <div className="flex px-10 w-full">
        <div className="flex flex-wrap pt-[50px] items-center flex md:flex-row flex-col  items-center">
          <div className="flex md:px-10 px-0  md: w-full">
            <div className="flex md:flex-row flex-col   items-center">
              <div className=" flex-2 md:pl-20  md:flex-2  relative md:mt-0 ">
                <div className="pr-5 relative">
                  <img className="circleimg" src="homepage/swim1.jpg" />
                </div>
              </div>

              {/* Swimming Pool */}
              <div className="flex-1 flex-wrap md:flex-1  mt-10 md:mt-0">
                <div className="heading-title text-center">Swimming Pool</div>
                <div className="heading-title text-center text-[30px]">
                  Design, Construction & Maintenance
                </div>
                <div className="text-content">
                  We are the leading water specialist contractor in the UAE, AWG
                  provides comprehensive landscape solutions from concept design
                  to construction and maintenance that includes swimming pools &
                  water features.<br></br>
                  Dive into luxury with our customized swimming pool designs,
                  blending seamlessly with lush surroundings. From contemporary
                  pools to natural retreats, our skilled team crafts pools that
                  elevate your outdoor living. Discover the epitome of
                  relaxation and style with our unparalleled poolscapes.
                </div>
                <div className="flex justify-center items-center mt-5">
                  <Link to="/services">
                    <button type="button" className="home-button  ">
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Interior design */}

      <div className="flex px-10 w-full">
        <div className="flex flex-wrap-reverse items-center flex md:flex-row flex-col pt-[50px] items-center">
          <div className="flex-1 flex-wrap   ">
            <div className="heading-title text-center md:text-center">
              Interior
            </div>
            <div className="heading-title text-center text-[36px]">
              Design & Decoration
            </div>
            <div className="text-content">
              Elevate your indoor spaces with our premier interior design and
              decoration services at AWG. Immerse yourself in a world of
              aesthetic brilliance as we seamlessly blend nature's beauty with
              innovative design concepts. Our expert team transforms interiors
              into captivating havens, harmonizing elements for an unparalleled
              ambiance. From botanical-inspired decor to bespoke furniture
              arrangements, we curate personalized interiors that reflect your
              unique style. Explore a spectrum of possibilities with our
              landscape-infused designs, fostering tranquility within your
              living spaces. Trust AWG for visionary interior transformations
              that redefine luxury. Unleash the potential of your indoors with
              our distinguished landscape-inspired interior solutions. Elevate
              your lifestyle today.
            </div>
            <div className="flex justify-center mt-5 items-center">
              <Link to="/services">
                <button type="button" className="home-button  ">
                  Learn More
                </button>
              </Link>
            </div>
          </div>

          <div className="flex-2 flex-wrap mb-10 md:mb-0  pt-[50px] relative md:justify-center md:items-center">
            {/* <div className=''>
              <img className="leaf design w-[590.60px] h-[425.15px]  left-[68px] top-[85px] absolute rounded-[400px] rotate-90" src="leafborder.png" />
            </div> */}
            <div className="pr-5">
              <img
                className="circleimg"
                src="homepage/interior-1.jpg"
                style={animationProps}
              />
            </div>
            <div className="p-0 ">
              {/* <div className="Ellipse6 w-[493px] h-[493px] top-0 left-0  absolute bg-lime-500 bg-opacity-40 rounded-full blur-[300px]" /> */}
            </div>
          </div>
        </div>
      </div>

      {/* Awg garden center */}
      <div className="flex px-10 w-full">
        <div className="flex flex-wrap pt-[50px] items-center flex md:flex-row flex-col  items-center">
          <div className="flex  md:w-full">
            <div className="flex md:flex-row flex-col  items-center">
              <div className=" flex-2 md:pl-20   md:flex-2  relative md:mt-0 mt-10">
                <div className="pr-5 relative">
                  <img className="circleimg" src="homepage/GC.jpg" />
                </div>
              </div>

              {/* Garden Centre */}

              <div className="flex-1 flex-wrap ">
                <div className="heading-title text-center">
                  AWG Garden Centre
                </div>
                {/* <div className="heading-title text-center text-[36px]">Design, Construction & Maintenance</div> */}
                <div className="text-content">
                  Discover unparalleled beauty at our Garden Center, where
                  nature meets elegance. AWG Garden Center is your one-stop
                  destination for a curated selection of vibrant plants,
                  exquisite flowers, and top-quality gardening essentials.
                  Immerse yourself in a world of lush greenery and blossoming
                  colors as you explore our carefully crafted outdoor haven.
                  From enchanting perennials to resilient shrubs, our Garden
                  Center offers a diverse range to transform your space into a
                  thriving oasis. Elevate your gardening experience with expert
                  advice from our knowledgeable staff. Explore endless
                  possibilities at our Garden Center – your gateway to a
                  blooming paradise. Embrace nature's artistry with us today!
                </div>
                <div className="flex justify-center items-center mt-5">
                  <Link to="/services">
                    <button type="button" className="home-button  ">
                      Learn More
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Why Us */}

      <div className="flex flex-wrap px-12 pb-6 ">
        <div className="sm:flex-1  flex-wrap  pt-[50px] ">
          <div className="heading-title">Why us</div>
          <div className="mb-5 relative text-neutral-500 text-sm font-semibold font-['Dubai'] leading-snug">
            Our team of experienced professionals have the expertise needed to
            design and implement landscaping solutions that meet your unique
            needs. We approach each project with a fresh and innovative
            perspective, incorporating the latest trends and techniques to
            create a stunning outdoor space that exceeds your expectations. We
            pride ourselves on using only high-quality materials and equipment
            to ensure that your landscaping project not only looks beautiful,
            but is also built to last.
          </div>

          <div className="flex flex-1  space-x-5 pt-5 items-center">
            <div className="whyus ">
              <div className=" w-full pt-5 flex items-center justify-center">
                <Icon
                  icon="mdi:clock"
                  color="#86c23a"
                  className="md:w-[53.43px] md:h-[57.72px]  h-[39px] w-[39px]"
                />
              </div>
              <div className="w-full text-center pt-2 text-lime-500 font-medium">
                Fastest Work
              </div>
            </div>

            <div className="whyus">
              <div className=" w-full pt-5 flex items-center justify-center">
                <Icon
                  icon="entypo:bar-graph"
                  color="#86c23a"
                  className="md:w-[53.43px] md:h-[57.72px]  h-[39px] w-[39px] "
                />
              </div>
              <div className="w-full text-center pt-2 text-lime-500 font-medium">
                High Skill
              </div>
            </div>

            <div className="whyus">
              <div className=" w-full pt-5 flex items-center justify-center">
                <Icon
                  icon="zondicons:thumbs-up"
                  color="#86c23a"
                  hFlip={true}
                  className="md:w-[53.43px] md:h-[57.72px]  h-[39px] w-[39px] "
                />
              </div>
              <div className="w-full text-center pt-2 text-lime-500 font-medium">
                Clean Work
              </div>
            </div>

            <div className="whyus">
              <div className=" w-full pt-5 flex items-center justify-center">
                <Icon
                  icon="healthicons:eco-care"
                  color="#86c23a"
                  className="md:w-[53.43px] md:h-[57.72px]  h-[39px] w-[39px] "
                />
              </div>
              <div className="w-full text-center pt-2 text-lime-500 font-medium">
                Proper Care
              </div>
            </div>
          </div>
        </div>
        <div className="sm:pl-[187px] pt-[50px] hidden md:block md:relative flex">
          <img
            className="Rectangle8 md:w-[333px] md:h-[360px] w-[200px] h-[200px]   "
            src="aboutus single plant.png"
          />
          {/* <div className="Ellipse6  w-[200px] h-[300px] top-0 right-0 absolute bg-lime-500 bg-opacity-40 rounded-full blur-[300px]" /> */}
        </div>
      </div>

      {/* ClientSection */}
      <div className="pt-8 w-full">
        <ClientSection />
      </div>
      {/* <div className="pt-8 w-full">
          <ClientReviewSlider />
        </div> */}
    </div>
  );
}

export default Home;
