import React from "react";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  
  return (
    <footer className=" w-full    bg-slate-400  py-7 px-5 md:px-20 ">
      <div className="grid md:grid-cols-4 grid-cols-2  justify-center w-full">
        {/* Logo Section */}
        <div className=" pr-3 flex-1 justify-center items-center text-center w-full">
          <div className=" bg-white rounded-3xl mx-5 md:mx-10">
            <img
              className="Rectangle37 rounded-[40px] "
              src="AWG-logo-BG-removed.png"
            />
          </div>
        </div>

        {/* service Section */}
        <div className="w-full">
          <div className="  md:text-sm  space-y-4 ">
            <div className=" ">
              <p className="container mx-auto   text-black text-2xl font-bold font-['Dubai']">
                Services
              </p>
            </div>
            <a href="/services">
              <div className="hover:-translate-y-1 transition ease-in-out delay-100 pt-2">
                <p className="container  text-black text-[15px] font-medium font-['Dubai'] leading-normal tracking-wide">
                  Landscape Design,
                  <br /> Construction & Maintenance
                </p>
              </div>
            </a>
            <a href="/services">
              <div className="hover:-translate-y-1 transition ease-in-out delay-100 pt-2">
                <p className="container  text-black text-[15px] font-medium font-['Dubai'] leading-normal tracking-wide">
                  Swimming Pool Design,
                  <br /> Construction & Maintenance
                </p>
              </div>
            </a>
            <a href="/services">
              <div className="hover:-translate-y-1 transition ease-in-out delay-100 pt-2">
                <p className="container  text-black text-[15px] font-medium font-['Dubai'] leading-normal tracking-wide">
                  Water Feature Design,
                  <br /> Construction & Maintenance
                </p>
              </div>
            </a>
            <a href="/services">
              <div className="hover:-translate-y-1 transition ease-in-out delay-100 pt-2">
                <p className="container  text-black text-[15px] font-medium font-['Dubai'] leading-normal tracking-wide">
                  Interior Design & <br />
                  Fit-Out Works
                </p>
              </div>
            </a>
          </div>
        </div>

        <div className=" flex-1 w-full pt-5 md:pt-0">
          <div className="    justify-between item-start space-y-2 ">
            <div className="space-y-4 ">
              <div className="text-start ">
                <p className="container mx-auto   text-black text-2xl font-bold font-['Dubai']">
                  Events
                </p>
              </div>
              <a href="/services">
                <div className="hover:-translate-y-1 transition ease-in-out delay-100 pt-2">
                  <p className="container   text-black text-[15px] font-medium font-['Dubai'] leading-normal tracking-wide">
                    Kids Birthday <br /> Celebration
                  </p>
                </div>
              </a>
              <a href="/services">
                <div className="hover:-translate-y-1 transition ease-in-out delay-100 pt-2">
                  <p className="container   text-black text-[15px] font-medium font-['Dubai'] leading-normal tracking-wide">
                    Junior Gardener <br />
                    Program
                  </p>
                </div>
              </a>
            </div>

            <div className="text-start mt-5 ">
              <p className="container mx-auto   text-black text-2xl font-bold font-['Dubai']">
                Online Shop
              </p>
            </div>
            <a href="/services">
              <div className="hover:-translate-y-1 transition ease-in-out delay-100 pt-2">
                <p className="container  text-black text-[15px] font-medium font-['Dubai'] leading-normal tracking-wide">
                  Indoor & Outdoor Plants
                </p>
              </div>
            </a>
            <a href="/services">
              <div className="hover:-translate-y-1 transition ease-in-out delay-100 pt-2">
                <p className="container  text-black text-[15px] font-medium font-['Dubai'] leading-normal tracking-wide">
                  Flower Bouquets
                </p>
              </div>
            </a>

            <div className="text-start ">

              <div>
                <ul>
                  <li>
                    
                  </li>
                </ul>
              </div>
              
            </div>
          </div>
        </div>
        <div className=" flex-1 w-full pt-5 md:pt-0">
          <div className="   justify-center item-start space-y-2  ">
            <div className="">
              <p className="container    text-black text-2xl font-bold font-['Dubai'] inline-flex">
                Contact Us
              </p>
            </div>
            <div className="flex ">
              <div className="flex">
                <svg
                  className="h-[30px] w-[30px]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="32"
                    d="M256 48c-79.5 0-144 61.39-144 137c0 87 96 224.87 131.25 272.49a15.77 15.77 0 0 0 25.5 0C304 409.89 400 272.07 400 185c0-75.61-64.5-137-144-137Z"
                  />
                  <circle
                    cx="256"
                    cy="192"
                    r="48"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="15"
                  />
                </svg>
                <p className=" pl-2  text-black text-[15px] font-medium font-['Dubai'] leading-normal tracking-wide">
                  AL HANAA CENTER - 221 Al Mankhool Rd - Al Jaffiliya - Dubai{" "}
                </p>
              </div>
            </div>
            <div className="flex mt-1 ">
              <div className="flex">
                <svg
                  className="h-[30px] w-[30px]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="256"
                  height="256"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="currentColor"
                    d="m222.37 158.46l-47.11-21.11l-.13-.06a16 16 0 0 0-15.17 1.4a8.12 8.12 0 0 0-.75.56L134.87 160c-15.42-7.49-31.34-23.29-38.83-38.51l20.78-24.71c.2-.25.39-.5.57-.77a16 16 0 0 0 1.32-15.06v-.12L97.54 33.64a16 16 0 0 0-16.62-9.52A56.26 56.26 0 0 0 32 80c0 79.4 64.6 144 144 144a56.26 56.26 0 0 0 55.88-48.92a16 16 0 0 0-9.51-16.62ZM176 208A128.14 128.14 0 0 1 48 80a40.2 40.2 0 0 1 34.87-40a.61.61 0 0 0 0 .12l21 47l-20.67 24.74a6.13 6.13 0 0 0-.57.77a16 16 0 0 0-1 15.7c9.06 18.53 27.73 37.06 46.46 46.11a16 16 0 0 0 15.75-1.14a8.44 8.44 0 0 0 .74-.56L168.89 152l47 21.05h.11A40.21 40.21 0 0 1 176 208Z"
                  />
                </svg>
                <p className=" pl-2 pt-1 text-black text-[15px] font-medium font-['Dubai'] leading-normal tracking-wide">
                  +971 45 915 444{" "}
                </p>
              </div>
            </div>
            <div className="flex mt-2 ">
              <div className="flex">
                <svg
                  className="h-[30px] w-[30px]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 4.99L4 6h16zm0 12H4V8l8 5l8-5v10z"
                  />
                </svg>
                <p className=" pl-3 pt-1 text-black text-[15px] font-medium font-['Dubai'] leading-normal tracking-wide">
                  info@awg-alghaithgroup.ae
                </p>
              </div>
            </div>

            <div className="text-start mt-3">
              <p className="container  text-black text-2xl font-bold font-['Dubai'] inline-flex">
                Find Us on
              </p>
            </div>
            <div className="flex mt-2">
              <div className="flex space-x-6 ">
                <div className="hover:-translate-y-1 transition ease-in-out delay-100">
                  <a href="https://www.facebook.com/agriculturalworldgardens?mibextid=ZbWKwL">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="27"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M9.101 23.691v-7.98H6.627v-3.667h2.474v-1.58c0-4.085 1.848-5.978 5.858-5.978c.401 0 .955.042 1.468.103a8.68 8.68 0 0 1 1.141.195v3.325a8.623 8.623 0 0 0-.653-.036a26.805 26.805 0 0 0-.733-.009c-.707 0-1.259.096-1.675.309a1.686 1.686 0 0 0-.679.622c-.258.42-.374.995-.374 1.752v1.297h3.919l-.386 2.103l-.287 1.564h-3.246v8.245C19.396 23.238 24 18.179 24 12.044c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.628 3.874 10.35 9.101 11.647Z"
                      />
                    </svg>
                  </a>
                </div>
                <div className="hover:-translate-y-1 transition ease-in-out delay-100">
                  <a href="https://instagram.com/agriculturalworldgardens?igshid=MzRlODBiNWFlZA==">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 15 15"
                    >
                      <path
                        fill="currentColor"
                        d="M7.5 5a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5Z"
                      />
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M4.5 0A4.5 4.5 0 0 0 0 4.5v6A4.5 4.5 0 0 0 4.5 15h6a4.5 4.5 0 0 0 4.5-4.5v-6A4.5 4.5 0 0 0 10.5 0h-6ZM4 7.5a3.5 3.5 0 1 1 7 0a3.5 3.5 0 0 1-7 0ZM11 4h1V3h-1v1Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </div>

                <div className="hover:-translate-y-1 transition ease-in-out delay-100">
                  <a href="https://www.linkedin.com/company/agriculture-world-gardens/?viewAsMember=true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="currentColor"
                        d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248c-.015-.709-.52-1.248-1.342-1.248c-.822 0-1.359.54-1.359 1.248c0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586c.173-.431.568-.878 1.232-.878c.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252c-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                      />
                    </svg>
                  </a>
                </div>

                <div className="hover:-translate-y-1 transition ease-in-out delay-100">
                  <a href="https://wa.me/message/47H7MC3V6O6FB1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="currentColor"
                        d="M13.95 4.24C11.86 1 7.58.04 4.27 2.05C1.04 4.06 0 8.44 2.09 11.67l.17.26l-.7 2.62l2.62-.7l.26.17c1.13.61 2.36.96 3.58.96c1.31 0 2.62-.35 3.75-1.05c3.23-2.1 4.19-6.39 2.18-9.71Zm-1.83 6.74c-.35.52-.79.87-1.4.96c-.35 0-.79.17-2.53-.52c-1.48-.7-2.71-1.84-3.58-3.15c-.52-.61-.79-1.4-.87-2.19c0-.7.26-1.31.7-1.75c.17-.17.35-.26.52-.26h.44c.17 0 .35 0 .44.35c.17.44.61 1.49.61 1.58c.09.09.05.76-.35 1.14c-.22.25-.26.26-.17.44c.35.52.79 1.05 1.22 1.49c.52.44 1.05.79 1.66 1.05c.17.09.35.09.44-.09c.09-.17.52-.61.7-.79c.17-.17.26-.17.44-.09l1.4.7c.17.09.35.17.44.26c.09.26.09.61-.09.87Z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>

            <div className="text-start mt-3">
              <p className="container]   text-black text-2xl font-bold font-['Dubai'] inline-flex">
                Get The App
              </p>
            </div>
            <div className="flex mt-2">
              <div className="flex space-x-6 ">
                <div className="hover:-translate-y-1 transition ease-in-out delay-100">
                  <a href="https://play.google.com/store/apps/details?id=com.awg.awggardens&pcampaignid=web_share">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M48 59.49v393a4.33 4.33 0 0 0 7.37 3.07L260 256L55.37 56.42A4.33 4.33 0 0 0 48 59.49ZM345.8 174L89.22 32.64l-.16-.09c-4.42-2.4-8.62 3.58-5 7.06l201.13 192.32ZM84.08 472.39c-3.64 3.48.56 9.46 5 7.06l.16-.09L345.8 338l-60.61-57.95ZM449.38 231l-71.65-39.46L310.36 256l67.37 64.43L449.38 281c19.49-10.77 19.49-39.23 0-50Z"
                      />
                    </svg>
                  </a>
                </div>
                <div className="hover:-translate-y-1 transition ease-in-out delay-100">
                  <a href="https://apps.apple.com/in/app/awg-gardens/id6447518076">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                        <path
                          fill="currentColor"
                          d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2Zm.5 11H6.3a1 1 0 1 0 0 2h.514l-.287.511a1 1 0 1 0 1.746.978L9.106 15H12.5a1 1 0 0 0 0-2Zm1.893-2.989a1 1 0 0 0-1.797.873l.052.105l3.08 5.5a1 1 0 0 0 1.796-.872l-.052-.106l-.286-.51h.514a1 1 0 0 0 .117-1.994L17.7 13h-1.634l-1.673-2.989Zm-2.52-4.5a1 1 0 0 0-1.797.873l.051.105l.727 1.297l-1.807 3.226a1 1 0 0 0 1.683 1.075l.063-.098l3.08-5.5a1 1 0 0 0-1.683-1.076l-.063.098L12 5.74l-.127-.227Z"
                        />
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto mt-5 text-center  text-stone-950 text-sm font-normal font-['Dubai'] leading-snug">
        <p>&copy; {new Date().getFullYear()} agricultural world gardens</p>
      </div>
    </footer>
  );
};

export default Footer;
