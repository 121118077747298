import React from 'react';
import ContactForm from '../../components/contactform';


function Contact() {
  return (
    <div className='w-full scroll-smooth  relative overflow-hidden bg-white opacity-100 p-0'>
      <div className=' items-center flex justify-center p-0'>
        <div className='container w-[800px]  m-[40px] items-center  justify-center  bg-white rounded-[30px] shadow-lg'>
          <div className='heading-title text-[40px] pt-0 mb-2 text-center'>
            Contact Us
          </div>

          <div className='flex flex-col sm:flex-row'>
            <div className='pl-8'>
              <div className="text-lime-600 text-lg font-medium font-['Dubai'] ">Address </div>
              <div className="text-black text-md font-semibold font-['Dubai'] leading-[30.50px]">AL HANAA CENTER -<br /> 221 Al Mankhool Rd - Al Jaffiliya <br /> Dubai
                <br />info@awg-alghaithgroup.ae<br />
                Tele: +971 45 91 5444<br />
                PO BOX : 90090 </div>
                <div className="flex mt-2">
              <div className="flex space-x-6 ">
                <div className="hover:-translate-y-1 transition ease-in-out delay-100">
                  <a href="https://www.facebook.com/agriculturalworldgardens?mibextid=ZbWKwL">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="27"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M9.101 23.691v-7.98H6.627v-3.667h2.474v-1.58c0-4.085 1.848-5.978 5.858-5.978c.401 0 .955.042 1.468.103a8.68 8.68 0 0 1 1.141.195v3.325a8.623 8.623 0 0 0-.653-.036a26.805 26.805 0 0 0-.733-.009c-.707 0-1.259.096-1.675.309a1.686 1.686 0 0 0-.679.622c-.258.42-.374.995-.374 1.752v1.297h3.919l-.386 2.103l-.287 1.564h-3.246v8.245C19.396 23.238 24 18.179 24 12.044c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.628 3.874 10.35 9.101 11.647Z"
                      />
                    </svg>
                  </a>
                </div>
                <div className="hover:-translate-y-1 transition ease-in-out delay-100">
                  <a href="https://instagram.com/agriculturalworldgardens?igshid=MzRlODBiNWFlZA==">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 15 15"
                    >
                      <path
                        fill="currentColor"
                        d="M7.5 5a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5Z"
                      />
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M4.5 0A4.5 4.5 0 0 0 0 4.5v6A4.5 4.5 0 0 0 4.5 15h6a4.5 4.5 0 0 0 4.5-4.5v-6A4.5 4.5 0 0 0 10.5 0h-6ZM4 7.5a3.5 3.5 0 1 1 7 0a3.5 3.5 0 0 1-7 0ZM11 4h1V3h-1v1Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </a>
                </div>

                <div className="hover:-translate-y-1 transition ease-in-out delay-100">
                  <a href="https://www.linkedin.com/company/agriculture-world-gardens/?viewAsMember=true">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="currentColor"
                        d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248c-.015-.709-.52-1.248-1.342-1.248c-.822 0-1.359.54-1.359 1.248c0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586c.173-.431.568-.878 1.232-.878c.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252c-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                      />
                    </svg>
                  </a>
                </div>

                <div className="hover:-translate-y-1 transition ease-in-out delay-100">
                  <a href="https://wa.me/message/47H7MC3V6O6FB1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="currentColor"
                        d="M13.95 4.24C11.86 1 7.58.04 4.27 2.05C1.04 4.06 0 8.44 2.09 11.67l.17.26l-.7 2.62l2.62-.7l.26.17c1.13.61 2.36.96 3.58.96c1.31 0 2.62-.35 3.75-1.05c3.23-2.1 4.19-6.39 2.18-9.71Zm-1.83 6.74c-.35.52-.79.87-1.4.96c-.35 0-.79.17-2.53-.52c-1.48-.7-2.71-1.84-3.58-3.15c-.52-.61-.79-1.4-.87-2.19c0-.7.26-1.31.7-1.75c.17-.17.35-.26.52-.26h.44c.17 0 .35 0 .44.35c.17.44.61 1.49.61 1.58c.09.09.05.76-.35 1.14c-.22.25-.26.26-.17.44c.35.52.79 1.05 1.22 1.49c.52.44 1.05.79 1.66 1.05c.17.09.35.09.44-.09c.09-.17.52-.61.7-.79c.17-.17.26-.17.44-.09l1.4.7c.17.09.35.17.44.26c.09.26.09.61-.09.87Z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
              <iframe className='my-6 rounded-lg' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14782425.33461685!2d37.01010009229298!3d25.23724291667305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43bae6035ed9%3A0x7cbd95017eba040f!2sAgricultural%20World%20Gardens!5e0!3m2!1sen!2sin!4v1696308301815!5m2!1sen!2sin" width="350" height="200" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <div className='flex-2 pl-8 sm:pl-[50px] w-full pr-8'>
              <div className="text-lime-600 text-lg font-medium font-['Dubai'] ">Send Message </div>
              <div className='pt-3'>
                <ContactForm />
              </div>
            </div>

          </div>


        </div>
      </div>

    </div>
  );
}

export default Contact;