import React from "react";

function Portfolio() {
  const projects = [
    // Add your project information here
    {
      name: "Azizi riviera",
      clientname:"AZIZI DEVELOPMENTS",
      image: "azzezi.jpg",
    },
    {
      name: "Inaya Facilities Management",
      image: "assets/projectimges/villa p36.jpg",
    },
    {
      name: "Transguard Group (Eagle Hills)",
      image: "assets/projectimges/eagle hills.jpg",
    },
    {
      name: "Sustainable City",
      image: "assets/projectimges/Dubai-Sustainable-City-1.jpg",
    },
    {
      name: "Arada Community Sharjah",
      image: "assets/projectimges/arada community sharjah.jpg",
    },
    {
      name: "Al Rostamani Villa",
      image: "assets/projectimges/rostamani villa.jpg",
    },
    {
      name: "Ariyana Tower",
      image: "assets/projectimges/ariyana tower.jpg",
    },
    {
      name: "Elysee by Pantheon",
      image: "assets/projectimges/Elysee-2-by-Pantheon.jpg",
    },
    {
      name: "Hive Residency",
      image: "assets/projectimges/hive residency.jpg",
    },
    {
      name: "Jabel Ali Recreation Center",
      image: "assets/projectimges/jabel ali recreation center.jpg",
    },
    {
      name: "Maison VI",
      image: "assets/projectimges/maison.jpg",
    },
    {
      name: "Meydan Villa",
      image: "assets/projectimges/meydan villa.jpg",
    },
    {
      name: "Royal JVC",
      image: "assets/projectimges/Pool in Royal JVC.jpg",
    },
    {
      name: "Sofitel Staff Accomodation",
      image: "assets/projectimges/sofitel.png",
    },
    {
      name: "Al Hana Center",
      image: "assets/projectimges/Al Hana mall.jpg",
    },
    {
      name: "Teejan Villas",
      image: "assets/projectimges/teejan villa.jpg",
    },
    {
      name: "Radisson Blu Hotel",
      image: "assets/projectimges/radisson blu.webp",
    },
    {
      name: "Hatta landmark heritage",
      image: "assets/projectimges/hatta.jpg",
    },

    // Add more project as needed
  ];

  return (
    <div className="w-full scroll-smooth  relative overflow-hidden bg-white opacity-100 p-0">
      <div className="flex ">
        <div className="flex flex-wrap-reverse">
          <div className="flex-1 flex-wrap px-10 md:pl-[100px] pt-[30px] ">
            <span className="heading-title text-[40px]">
              Go{" "}
              <span className="heading-title text-[40px] text-lime-500">
                Greener
              </span>{" "}
              Now{" "}
              <span className="heading-title text-[40px] text-lime-500"></span>
              <br />
              With
              <span className="heading-title text-[40px] text-lime-500">
                {" "}
                AWG
              </span>
            </span>
            <div className=" pt-3 h-[100px] relative text-neutral-500 text-sm font-normal font-['Dubai'] leading-snug">
              Our project page isn't just about showcasing our work; it's about
              inspiring you. Whether you're a homeowner looking to transform
              your backyard, a business owner seeking to enhance your commercial
              space, or simply someone who appreciates the beauty of landscapes,
              these projects serve as a source of inspiration. We're here to
              turn your landscape dreams into a reality.{" "}
            </div>
          </div>

          <div className="flex-2 flex-wrap pl-[60px] md:pl-[147px] pt-[0px] ">
            <div className="pr-1">
              <img
                className="Rectangle8 w-[450px] h-[350px] "
                src="servicetopimg.png"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="heading-title text-center pt-8 mt-8 text-black text-[40px] font-bold font-['Dubai'] leading-[50px]">
        Our Projects
      </div>

      <div className=" pt-[20px] md:grid-flow-row md:grid-cols-4  grid grid-flow-cols grid-cols-1  gap-y-7 gap-x-3 flex justify-center items-center">
        {projects.map((project, index) => (
          <div className=" bg-white-500 pl-5 hover:-translate-y-5 transition ease-in-out delay-100">
            <div className=" relative flex justify-center items-center ">
              <img
                className="Rectangle15 w-[295px] h-[300px] rounded-[30px]  shadow hover:shadow-2xl hover:shadow-lime-200  "
                src={`${project.image}`} // Replace with your image path
                alt={project.name}
              />
            </div>
            <div className="text-center text-black text-[20px] font-normal font-['Dubai'] leading-[50px] pt-3">
              {project.name}
            </div>
            {/* <div className="text-center text-black text-[20px] font-normal font-['Dubai'] leading-[50px] pt-0">
              {project.clientname}
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Portfolio;
