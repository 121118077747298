import React from "react";
import Layout from "../../components/layout";

function About() {
  return (
    <div className="aboutus w-full scroll-smooth relative  bg-white opacity-100 p-10">
      <div className="flex-col md:flex-row flex mt-4 ">
        <div className="flex-1">
          <div className="md:ml-5 flex justify-center">
            <img
              className="Logo w-48 md:w-[494px]  md:ml-20"
              src="AWG logo hd.jpg"
            />
          </div>
        </div>
        <div className=" md:flex-1 md:pr-10 ">
          <div className="heading-title text-center md:text-start py-2">
            About Us
          </div>
          <div className=" relative text-neutral-500 text-sm md:text-base text-center md:text-start font-normal font-['Dubai'] leading-snug">
            Welcome to <b>Agricultural World Gardens</b>, a premier destination for all
            your landscaping and plant needs. With a passion for nature and a
            commitment to excellence, we strive to provide you with the highest
            quality plants and exceptional landscaping services.
            <br />
            <br />
            At <b>Agricultural World Gardens</b>, we understand the importance of
            creating a harmonious outdoor environment that reflects your unique
            style and enhances your overall well-being. Our team of expert
            landscape designers and horticulturists are dedicated to helping you
            transform your outdoor space into a breathtaking oasis that will
            leave a lasting impression.
            <br /> <br />
            We take great pride in offering an extensive selection of plants,
            ranging from vibrant flowers to lush trees and shrubs. Whether you
            are looking to add a touch of color to your garden or create a
            sustainable landscape, our diverse range of plants caters to every
            gardening need.
            <br /> <br />
            To ensure the utmost satisfaction, we source our plants from trusted
            nurseries and growers, guaranteeing their health and vitality. Each
            plant is carefully selected and nurtured to thrive in various
            climates and soil conditions, enabling you to enjoy a vibrant and
            thriving garden all year round.
            <br /> <br />
            In addition to our exceptional plant selection, Agricultural World
            Gardens also offers a wide range of landscaping services. Our team
            of experienced professionals is equipped to handle everything from
            landscape design and installation to regular maintenance. We
            understand that every project is unique, and we work closely with
            you to bring your vision to life, creating a space that is both
            functional and visually captivating.
            <br /> <br />
            To make your experience with us seamless and convenient, our website
            provides detailed information on each plant, along with expert tips
            and advice for successful gardening. Our friendly customer service
            team is also available to answer any questions you may have and
            provide personalized recommendations.
            <br /> <br />
            At Agricultural World Gardens, we believe that a flourishing outdoor
            space has the power to inspire, rejuvenate, and connect us with
            nature. We are committed to helping you achieve your landscaping
            goals with integrity, creativity, and exceptional customer service.
            Explore our website and let us assist you in creating the garden of
            your dreams.
          </div>
        </div>
      </div>

      <div className="heading-title text-center py-5 text-black text-[40px] font-bold font-['Dubai'] leading-[50px]">
        Mission
      </div>
      <div className="flex items-center justify-center">
        <div className="s flex  max-w-[574px] text-center  text-neutral-500  font-medium font-['Dubai'] leading-snug">
          AWG provides services and solutions to support the growth of green
          life which recognizes our contribution to the health and well-being of
          the environment, communities and people AWG is passionate to provide a
          modern educational garden center for kids.
        </div>
      </div>
      <div
        className="w-[356px] h-[245px] absolute right-0 hidden lg:block ">
        <img src="assets/aboutus/aboutusplant.png" />
        <div class="w-[200px] h-[300px] absolute top-0 bg-lime-500 bg-opacity-40 rounded-full blur-[300px]"></div>
      </div>

      <div className="heading-title text-center py-5 text-black text-[40px] font-bold font-['Dubai'] leading-[50px]">
        Vision
      </div>
      <div className="flex items-center justify-center">
        <div className="s flex  max-w-[574px] text-center  text-neutral-500  font-medium font-['Dubai'] leading-snug">
          AWG is committed to developing sustainable, long term relationships
          with our customers. We achieve this by working in close collaboration
          with our customers to deliver high-quality, value for money grounds
          maintenance and landscaping services.
        </div>
      </div>

      <div className="heading-title text-center py-5 text-black text-[40px] font-bold font-['Dubai'] leading-[50px]">
        Our Principle
      </div>
      <div className="flex items-center justify-center">
        <div className="s flex  max-w-[574px] text-center  text-neutral-500  font-medium font-['Dubai'] leading-snug">
          The service AWG provide, from initial enquiry through to
          implementation and completion are managed in accordance with a quality
          plan tailored to meet the client’s specific requirements. In order to
          ensure compliance with AWG Management System, regular internal audits
          are carried out throughout the company
        </div>
      </div>

      <div className="heading-title text-center py-5 text-black text-[40px] font-bold font-['Dubai'] leading-[50px]">
        Value Proposition
      </div>
      <div className="flex items-center justify-center">
        <div className="s flex  max-w-[574px] text-center  text-neutral-500  font-medium font-['Dubai'] leading-snug">
          A keen understanding of infrastructure networks, building massing,
          social patterns, commercial matters, development regulations and open
          space is what sets us apart. Working at any scales A to Z area of
          landscaping, water feature, irrigation & Interior decoration, we can
          coordinate or extend the design, we understanding of infrastructure
          networks, building massing, social patterns, commercial sector,
          development regulations and open space enables us to develop spaces
          that are transformative and highly sustainable.
        </div>
      </div>

      <div className="heading-title text-center py-5 text-black text-[40px] font-bold font-['Dubai'] leading-[50px]">
        Meet The Team
      </div>
      <div className="flex justify-center items-center">
        <div className="smallimg3 flex justify-between item-centre">
          <div className="flex space-x-10 md:space-x-20 pt-10">
            <a href="https://www.linkedin.com/in/talghaith/">
            <div className="flex-1 hover:-translate-y-2 transition ease-in-out delay-100">
              {/* <div className="Ellipse6 w-52 h-52  absolute bg-lime-500 bg-opacity-40 rounded-full blur-[300px]" /> */}
              <img
                className=" small-img "
                src="assets/aboutus/Tariq Sir.jpg"
              />
              <p className="text-center mt-4 text-neutral-500 text-sm font-semibold font-['Dubai'] leading-snug">
                Tariq M. Al Ghaith
              </p>
              <p className="text-center mt-0 text-neutral-500 text-sm font-normal font-['Dubai'] leading-snug">
                Chairman
              </p>
            </div>
            </a>

            <a href="">
            <div className="flex-1 hover:-translate-y-2 transition ease-in-out delay-100">
              {/* <div className="Ellipse6 w-52 h-52  absolute bg-lime-500 bg-opacity-40 rounded-full blur-[300px]" /> */}

              <img
                className="small-img"
                src="assets/aboutus/"
              />
              <p className="text-center mt-4 text-neutral-500 text-sm font-semibold font-['Dubai'] leading-snug">
                Khalaf Al Ghaith
              </p>
              <p className="text-center mt-0 text-neutral-500 text-sm font-normal font-['Dubai'] leading-snug">
                Managing Director
              </p>
            </div>

            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
