// import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
 HashRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import About from "./pages/About Us";
import Services from "./pages/Services";
import Portfolio from "./pages/portfolio";
import Contact from "./pages/Contact Us";
import Home from "./pages/Home";
import Navbar from "./navbar";
import Footer from "./components/footer";
import ServicesCategory from "./pages/ServiceCategories";
import Clients from "./pages/clients";
import WhatsAppButton from "./components/whatsapp";
import Blogs from "./pages/Blogs";

function App() {
  return (
    <div className="App">
      <Router>
        <div className="">
          <Navbar />
        </div>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/servicecategory" element={<ServicesCategory />} />
          <Route path="/blogs" element={<Blogs />} />
        </Routes>
      </Router>
      <WhatsAppButton />
      <Footer />
    </div>
  );
}

export default App;
