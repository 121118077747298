import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          // slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          // slidesToScroll: 1,
        },
      },
    ],
  };



  return (
    <div>
      <div className=" ">
        <Slider {...settings}>
          {/* Your carousel slides go here */}

          <div className="relative  ">
            <div className=" flex  bg-black ">
            <img
              src="assets/slider_img/Swimming Pool Maintenance.jpg"
              alt="Slide 1"
              className=" h-[300px] md:h-[500px] w-full"
            />
              <div className="absolute bottom-10 left-5">
                <span className="font-bold font-['Dubai'] leading-snug sm:text-[55px] text-[25px] text-white">
                  Swimming {" "}
                  <span className="text-lime-500">Pool </span>
                  <br />
                  <span className="text-lime-500">Maintenance </span> 
                </span>
              </div>
            </div>
          </div>

          <div className="relative ">
            <div className=" flex w-full   bg-black ">
            <img
              src="assets/slider_img/maintenance.jpg"
              alt="Slide 2"
              className=" h-[300px] md:h-[500px] w-full "
            />
              <div className="absolute bottom-10 left-5">
                <span className="font-bold font-['Dubai'] leading-snug sm:text-[55px] text-[25px] text-white">
                Garden
                <br/>
                  <span className="text-white-500">Maintenance</span>
                </span>
              </div>
            </div>
          </div>

          <div className="relative ">
            <div className=" flex w-full   bg-black ">
            <img
              src="assets/slider_img/Landscaping Construction.jpg"
              alt="Slide 2"
              className=" h-[300px] md:h-[500px] w-full "
            />
              <div className="absolute bottom-10 left-5">
                <span className="font-bold font-['Dubai'] leading-snug sm:text-[55px] text-[25px] text-white">
                
                  <span className="text-lime-500">Landscape </span>
                  <br />
                  <span className="text-lime-500">Construction </span> & Design
                </span>
              </div>
            </div>
          </div>

          <div className="relative ">
            <div className=" flex w-full   bg-black ">
            <img
              src="assets/slider_img/Untitled design.jpg"
              alt="Slide 2"
              className=" h-[300px] md:h-[500px] w-full "
            />
              <div className="absolute bottom-10 left-5">
                <span className="font-bold font-['Dubai'] leading-snug sm:text-[55px] text-[25px] text-white">
                  
                  <span className="text-lime-500">Swimming Pool </span>
                  <br />
                  <span className="text-lime-500">Design </span> & Construction
                </span>
              </div>
            </div>
          </div>

          <div className="relative ">
            <div className=" flex w-full   bg-black ">
            <img
              src="assets/slider_img/AWG ACCEPT ESAAD CARD.jpg"
              alt="Slide 2"
              className=" h-[300px] md:h-[500px] w-full "
            />
              {/* <div className="absolute bottom-10 left-5">
                <span className="font-bold font-['Dubai'] leading-snug sm:text-[55px] text-[25px] text-white">
                  
                  <span className="text-lime-500">Swimming Pool </span>
                  <br />
                  <span className="text-lime-500">Design </span> & Construction
                </span>
              </div> */}
            </div>
          </div>

          




          

          


          
          
          {/* Add more slides as needed */}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
{
  /* <img
              src="victor-furtuna-TXd1kXSlMic-unsplash.jpg"
              alt="Slide 3"
              className=" h-[500px] w-full "
            /> */
}
