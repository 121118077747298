import React from "react";

function Clients() {
  const clients_logo = [
    // Add your client logo information here
    "assets/logos/land-department-logo-B5107EA90A-seeklogo.jpg",
    "assets/logos/azizi.1.jpg",
    "assets/logos/airport.jpg",
    "assets/logos/cropped-inaya-2.jpg",
    "assets/logos/al-hathboor-logo-28812B3FA5-seeklogo.jpg",
    "assets/logos/Al_Wasl_Club_Logo.jpg",
    "assets/logos/al-mazroui-medical-center-logo.jpg",
    "assets/logos/beeah.jpg",
    "assets/logos/city-centre-fujairah-logo-vector.jpg",
    "assets/logos/emiratesparkresort.jpg",
    "assets/logos/festival-city.jpg",
    "assets/logos/golf-emirates.jpg",
    "assets/logos/healthcare-city.jpg",
    "assets/logos/hyundai-png-12673.jpg",
    "assets/logos/international-financial-centre.jpg",
    "radisson.png",
    "assets/logos/land-department-logo-B5107EA90A-seeklogo.jpg",
    "assets/logos/landing-logo.jpg",
    "assets/logos/meydan-logo-300x121.jpg",
    "assets/logos/modern-bakery-logo-300.jpg",
    "assets/logos/muncipality.jpg",
    "assets/logos/nad-al-sheba-color.jpg",
    "assets/logos/nakheel.jpg",
    "assets/logos/pngegg.jpg",
    "assets/logos/rameelogo_aslda1.jpg",
    "assets/logos/rta.jpg",
    "assets/logos/sammangroup.jpg",
    "assets/logos/sports-city.jpg",
    "assets/logos/sports-council.jpg",
    "assets/logos/TECOM_Group.jpg",
    "assets/logos/teejan-logo.jpg",
    "assets/logos/tiger-properties-logo-en4.jpg",
    "assets/logos/wasl-asset-management.jpg",

    // Add more clients as needed
  ];

  return (
    <div className="Home w-full scroll-smooth relative overflow-hidden bg-white opacity-100 p-0">
      <div className="flex ">
        <div className="flex flex-wrap-reverse">
          <div className="flex-1 flex-wrap px-10 md:pl-[100px] pt-[30px] ">
            <span className="heading-title text-[40px]">
              Let's{" "}
              <span className="heading-title text-[40px] text-lime-500">
                Our
              </span>{" "}
              Clients{" "}
              <span className="heading-title text-[40px] text-lime-500">
                Speak
              </span>
              <br />
              About
              <span className="heading-title text-[40px] text-lime-500">
                {" "}
                AWG
              </span>
            </span>
            <div className=" pt-3 h-[100px] relative text-neutral-500 text-sm font-normal font-['Dubai'] leading-snug">
              At Agricultural World Gardens, our clients are at the heart of
              everything we do. We cater to a diverse and dynamic community of
              individuals, businesses, and organizations, each with unique needs
              and goals. Our clients include: Individuals, Small Businesses,
              Enterprises, Nonprofits, Educational Institutions, Government
              Entities.{" "}
            </div>
          </div>

          <div className="flex-2 flex-wrap pl-[60px] md:pl-[147px] pt-[0px] ">
            <div className="pr-1">
              <img
                className="Rectangle8 w-[400px] h-[350px] "
                src="assets/logos/clientimage.png"
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="heading-title text-center pt-11 text-black text-[40px] font-bold font-['Dubai'] leading-[50px]">
          Our Clients
        </div>

        <div className="grid grid-flow-row grid-cols-4 gap-x-10 px-10 pt-0">
          {clients_logo.map((client, index) => (
            <img
              src={`${client}`}
              alt={`${client.name}`}
              className="w-400 h-250"
            ></img>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Clients;
