import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@material-tailwind/react";


function Services() {
  const location = useLocation();
  const clients = [
    // Add your client information here
    {
      name: "Service 1",
      id:0,
      image: "assets/servicesImg/hardscaping&Paving.jpg",
      heading: "Hardscape, Paving & Civil Construction",
      description:
        "AWG offers an extensive range of hard landscaping services including civil works, all types of paved surfaces like streets and…",
    },

    {
      name: "Service 2",
      id:1,
      image: "assets/servicesImg/electrowater.jpg",
      heading: "Swimming Pools & Water Features",
      description:
        "AWG are specialized in the construction of water feature and swimming pools that comply with international standards…",
    },
    {
      name: "Service 3",
      image: "assets/projectimges/hatta.jpg",
      id:2,
      image: "assets/servicesImg/preconstruction.jpg",
      heading: "Theming Works To Define Projects Identity",
      description:
        "Over 80% of construction projects are identical such as: internal roads, walls, glass, steel, blocks, landscaping, doors, plumbing, electrics, etc….",
    },

    {
      name: "Client 4",
      id:3,
      image: "assets/servicesImg/preconstruction.jpg",
      heading: "Pre-Construction",
      description:
        "Is a service used in process planning of construction project before the actual execution begins. It’s a modern practice, considered…",
    },
    {
      name: "Client 5",
      id:4,
      image: "assets/servicesImg/irrigation.jpg",
      heading: "Irrigation & Water Management",
      description:
        "Water is the crucial element required for the nourishment of every elegant landscape; Water management is the main focus for…",
    },
    {
      name: "Client 6",
      id:5,
      image: "assets/servicesImg/lighting.jpg",
      heading: "Landscape Lighting Supply And Installation",
      description:
        "Landscape lighting systems are provided to illuminate and enhance the beautification of the landscaping works at night with the safety…",
    },

    {
      name: "Client 7",
      id:6,
      image: "assets/servicesImg/indoorplants.jpg",
      heading: "Propagation & Supply Of High Quality Indoor And Outdoor Plants",
      description:
        "With a total area of over 350,000m2 located in Al Wathba; we produces and acclimatizes a wide range of outdoor…",
    },
    {
      name: "Client 8",
      id:7,
      image: "assets/servicesImg/flower.jpg",
      heading:
        "Flowers, Indoor Plants &Amp; Accessories Trading &Amp; Distribution",
      description:
        "As a leading wholesaler of Cut Flowers and all Floral Accessories, as well as Indoor Plants and Decorative Containers, you…",
    },

    {
      name: "Client 9",
      id:8,
      image: "assets/servicesImg/ground.jpg",
      heading:
        "Ground, Landscape & Irrigation Operations & Maintenance Urban Furniture",
      description:
        "AWG provides Green Asset Management. The care and maintenance of new and established living landscapes…",
    },

    {
      name: "Client 10",
      id:9,
      image: "assets/servicesImg/landscapeschool.jpg",
      heading: "Landscape Commercial & Residential Installation",
      description:
        "AWG can landscape a single residence, municipal, educational, industrial, institutional and commercial projects up to a suburb of a…",
    },
    {
      name: "Service 3",
      id:10,
      image: "assets/servicesImg/pointing-sketch.jpg",
      heading: "Professional Project Management",
      description:
        "AWG create an internal Project Management work instruction which act as a reference….",
    },
    {
      name: "Client 1",
      id:11,
      image: "assets/servicesImg/shades.jpeg",
      heading: "Shade Structure Fabrication, Supply And Installation",
      description:
        "Elegance and style combined with cutting-edge engineering know-how..",
    },
    {
      name: "Client 1",
      id:12,
      image: "assets/servicesImg/tiles.jpg",
      heading:
        "Supply Of Tiles, Vinyl Flooring, Outdoor Lighting &Amp; Urban Furniture",
      description:
        "AWG has worldwide connections (Europe, America, Africa, Far East..) to provide the best quality…",
    },
    {
      name: "Client 1",
      id:13,
      image: "assets/servicesImg/stampedConcrete.jpg",
      heading: "Design & Installation Of Stamped Concrete & Decorative Asphalt",
      description:
        "AWG is the Exclusive Agent for the Middle East & North Africa (MENA)…",
    },

    // Add more clients as needed
  ];

  return (
    <div className="Home w-full scroll-smooth flex flex-col   bg-white opacity-100 ">
      <div className="flex ">
        <div className="flex flex-wrap-reverse">
          <div className="flex-1 flex-wrap px-10 md:pl-[100px] pt-[30px] ">
            <span className="heading-title text-[40px]">
              Enhance Your{" "}
              <span className="heading-title text-[40px] text-lime-500">
                Space
              </span>{" "}
              Into{" "}
              <span className="heading-title text-[40px] text-lime-500">
                Green
              </span>
              <br />
              With
              <span className="heading-title text-[40px] text-lime-500">
                {" "}
                AWG
              </span>
            </span>
            <div className=" pt-3 h-[100px] relative text-neutral-500 text-sm font-normal font-['Dubai'] leading-snug">
              Transforming your space into a lush oasis of greenery is easier
              than ever with the help of professional landscaping services.
              Whether you're looking to enhance the beauty of your residential
              or commercial property, landscaping can make a significant
              difference in creating an inviting and eco-friendly environment.{" "}
            </div>
          </div>

          <div className="flex-2 flex-wrap pl-[60px] md:pl-[147px] pt-[0px] ">
            <div className="pr-1">
              <img
                className="Rectangle8 w-[450px] h-[350px] "
                src="servicetopimg.png"
              />
            </div>
          </div>
        </div>
      </div>

      <div className=" text-center pt-5 text-black text-[40px] font-bold font-['Dubai'] leading-[50px] mt-8">
        Our Services
      </div>

      <div className="pt-[150px] px-5 my-10 grid grid-cols-1 md:grid-cols-3 gap-x-10 gap-y-44 ">
        {clients.map((client, index) => (
          <div className="text-center border  bg-white-500 rounded-2xl">
            <div className="relative flex justify-center items-center">
              <div className=" top-[-132px]   absolute   ">
                <img
                  className="Rectangle15 w-56 h-56  mx-auto rounded-[400px]  shadow hover:shadow-lg hover:shadow-lime-200"
                  src={`${client.image}`} // Replace with your image path
                  alt={client.name}
                />
              </div>
              <div className="   relative flex justify centre">
                <div className="Ellipse6 w-[200px] h-[300px] top-[-170px] absolute bg-lime-500 bg-opacity-40 rounded-full blur-[300px]" />
              </div>

              <div className='mx-auto'>
                {/* <div className=' pl-[80px] pt-[30px] relative'>
                  <img className="leaf w-[320.40px] h-[265.93px]  top-[-170px] absolute  -rotate-90" src="leafborder.png" />
                </div> */}
                <div className='   relative flex justify centre' >
                  <div className="Ellipse6 w-[200px] h-[200px] top-[-170px] absolute bg-lime-500 bg-opacity-40 rounded-full blur-[300px]" />
                </div>
              </div>
            </div>
            <div className="text-center pt-28 text-black text-[28px] font-medium font-['Dubai'] leading-9">
              {client.heading}
            </div>
            <div className="text-center px-[30px] text-neutral-500 text-base font-medium font-['Dubai'] leading-snug">
              {client.description}
            </div>
            <div className="mb-0">
            <Link to={"/servicecategory?id="+client.id}>
                    <button type="button" className="home-button  ">
                      Learn More
                    </button>
                  </Link>

              {/* <Button
                type="button"
                onClick={() => {
                  window.location.href = "/servicecategory?id="+client.id;
                }}
                className="home-button  my-5"
              >
                Learn More
              </Button> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
