import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ClientSection = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4, // Adjust the number of slides to show
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const clients = [
    // Add your client information here
    'assets/logos/land-department-logo-B5107EA90A-seeklogo.jpg',
    'assets/logos/azizi.1.jpg',
    'assets/logos/airport.jpg',
    'assets/logos/cropped-inaya-2.jpg',
    'assets/logos/al-hathboor-logo-28812B3FA5-seeklogo.jpg',
    'assets/logos/Al_Wasl_Club_Logo.jpg',
    'assets/logos/al-mazroui-medical-center-logo.jpg',
    'assets/logos/beeah.jpg',
    'assets/logos/city-centre-fujairah-logo-vector.jpg',
    'assets/logos/emiratesparkresort.jpg',
    'assets/logos/festival-city.jpg',
    'assets/logos/golf-emirates.jpg',
    'assets/logos/healthcare-city.jpg',
    'assets/logos/hyundai-png-12673.jpg',
    'assets/logos/international-financial-centre.jpg',
    'radisson.png',
    'assets/logos/land-department-logo-B5107EA90A-seeklogo.jpg',
    'assets/logos/landing-logo.jpg',
    'assets/logos/meydan-logo-300x121.jpg',
    'assets/logos/modern-bakery-logo-300.jpg',
    'assets/logos/muncipality.jpg',
    'assets/logos/nad-al-sheba-color.jpg',
    'assets/logos/nakheel.jpg',
    'assets/logos/pngegg.jpg',
    'assets/logos/rameelogo_aslda1.jpg',
    'assets/logos/rta.jpg',
    'assets/logos/sammangroup.jpg',
    'assets/logos/sports-city.jpg',
    'assets/logos/sports-council.jpg',
    'assets/logos/TECOM_Group.jpg',
    'assets/logos/teejan-logo.jpg',
    'assets/logos/tiger-properties-logo-en4.jpg',
    'assets/logos/wasl-asset-management.jpg',


    // Add more clients as needed
  ];

  return (
    <div className="bg-white-500 py-8 w-full   relative opacity-100 p-0">
      <div className="container mx-auto">
        <h2 className="heading-title text-center font-semibold text-[36px]">Our Clients</h2>
        <div className=" py-5">
          <Slider {...settings}>
            {clients.map((client, index) => (
              <div key={index} className="px-4">
                <img
                  src={`${client}`} // Replace with your image path
                  alt={client.name}
                  className="mx-auto w-32 h-32 object-contain"
                />
                {/* <p className="text-center mt-4">{client.name}</p> */}
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ClientSection;