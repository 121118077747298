import React, { useState } from "react";

const ListWithDropdown = ({ items }) => {
  const [expandedItem, setExpandedItem] = useState(null);

  const toggleDescription = (itemIndex) => {
    if (expandedItem === itemIndex) {
      setExpandedItem(null);
    } else {
      setExpandedItem(itemIndex);
    }
  };

  return (
    <ul className="space-y-1">
      {items.map((item, index) => (
        <li key={item.id} className="rounded-lg w-full hover:">
          <div className=" ">
            
            <button
              onClick={() => toggleDescription(index)}
              className=" w-full border-2 border-lime-500 text-center text-black text-[14px] font-normal rounded-full  justify-center font-['Dubai'] items-center inline-flex hover:bg-lime-400"
            >
              {item.name}
            </button>
          </div>
          {expandedItem === index && (
            
            <img
              src={`${item.image}`}
              alt="image"
              className=" h-[150px] w-full px-5 py-2 rounded-[40px]"
            />
            
          )}
          {expandedItem === index && (
            
            <p className="mt-1 w-full px-4 text-center text-[12px] ">{item.description}</p>
            
          )}
        </li>
      ))}
    </ul>
  );
};

export default ListWithDropdown;
