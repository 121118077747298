import React, { useEffect, useState } from "react";
import ListWithDropdown from "../../components/smallscreenservice";

const itemList = [
  {
    id: 0,
    name: "Hardscape, Paving & Civil Construction",
    image: "assets/servicesImg/hardscaping&Paving.jpg",
    description:
      "AWG offers a wide variety of hard landscaping services, including retaining walls, structures, civil works and other kinds of concrete surfaces including streets and pavements. Our projects are carried out using state-of-the-art machinery and a highly qualified crew that excels at site cooperation and coordination, guaranteeing timely and economical project delivery. To evaluate and provide Value Engineering recommendations, we work closely with engineers and subcontractors, ensuring your complete satisfaction with the output.",
  },
  {
    id: 1,
    name: "Electro-Mechanical & Water Features",
    image: "assets/servicesImg/electrowater.jpg",
    description:
      "AWG specialises in building water features and swimming pools in accordance with international standards, guaranteeing the greatest calibre of workmanship. We have a specialised team of professionals within the AWG portfolio that have the skills and knowledge to help you develop and design something truly outstanding. We follow the strictest guidelines from conception to completion, making sure that your pond, water features or swimming pool is built flawlessly in every way.",
  },
  {
    id: 2,
    name: "Theming Works To Define Projects Identity",
    image: "assets/servicesImg/preconstruction.jpg",
    description:
      'The power of landscaping and theming can elevate a project, and AWG Visioneering takes pride in going above and beyond to develop identities that are genuinely unique. We have successfully displayed our competence in major venues like the Formula 1 Grand Prix and Ferrari World, where the competitive environment in the UAE demands distinctive projects. Our imaginative team created breathtaking settings that made an impact by fusing their global experience with creative excellence.Our impressive landscaping job, in which we artfully sculpted the grass to spell out "Abu Dhabi" in an aesthetically beautiful way, was one of our most outstanding accomplishments. This artistic venture displayed our commitment to original thinking and close attention to detail. We turned the ordinary into remarkable by effortlessly incorporating landscape components, making a lasting impression on the environment. Beyond simply fulfilling the requests of the client, we take satisfaction in internally conceptualising complete themes. Understanding our clients goals and utilising our experience allows us to create distinctive, highly profitable products that benefit both our customers and the community. Every project our team works on reflects the dedication to excellence of our team, from the carefully chosen plantings to the meticulous sculpting.',
  },
  {
    id: 3,
    name: "Pre-Construction",
    image: "assets/servicesImg/preconstruction.jpg",
    description:
      "The most extensive range of goods and services currently offered on the market is provided and delivered thanks to AWGs close working relationships with a limited group of specialised suppliers and subcontractors.We keep a network of pre-approved suppliers and contractors, who must adhere to AWGs exacting requirements for Health & Safety and Quality Assurance, to further strengthen our capabilities and experience. Through this network, we have access to more tools and information, further solidifying our dedication to excellence in all facets of our business.  ",
  },
  {
    id: 4,
    name: "Irrigation & Water Management",
    image: "assets/servicesImg/irrigation.jpg",
    description:
      "Water is the most important element for the nourishment of every magnificent landscape. We specialise in water management at AWG Engineering Services and are committed to providing outstanding design solutions for automatic irrigation systems that smoothly integrate with your environment while following to ESTIDAMA requirements and emphasising water conservation.Our staff uses the most recent innovations in irrigation technology to ensure that installations are professionally commissioned to protect your landscape's natural shine and beauty.",
  },
  {
    id: 5,
    name: "Landscape Lighting Supply And Installation",
    image: "assets/servicesImg/lighting.jpg",
    description:
      "Landscape lighting systems serve multiple purposes, including enhancing the beauty of landscaping work, ensuring safety and security, providing accessibility, supporting recreational activities, fostering social interactions, and creating an ambiance for events during nighttime.AWG offers comprehensive lighting solutions, covering every aspect from initial concept design to system testing and commissioning. Our expertise ensures a seamless and efficient process.AWG incorporates innovative elements in the concept design. This includes the utilization of solar power, low voltage fixtures, LED technology, energy-efficient lamp bulbs such as xenon, and fiber optics. These advancements in the field of landscape lighting contribute to energy conservation and promote environmental stewardship.",
  },
  {
    id: 6,
    name: "Propagation & Supply Of High Quality Indoor And Outdoor Plants",
    image: "assets/servicesImg/indoorplants.jpg",
    description:
      "AWG Nursery, located in Al Wathba, boasts a total area of over 350,000m2, dedicated to producing and acclimatizing a wide range of high-quality outdoor and indoor plants suitable for the local and regional markets. Our specialization in the importation of exotic plant species allows us to meet even the most specific project requirements while maintaining large stock holdings for immediate sale and use.We are committed to being a leading force in the nursery industry in the region, and to contributing positively to the horticultural industry as a whole. Our dedication to social responsibility is reflected in our satisfaction, enjoyment, and pride in our achievements, as well as our eagerness to exchange knowledge and skills with our peers.",
  },
  {
    id: 7,
    name: "Flowers, Indoor Plants &Amp; Accessories Trading &Amp; Distribution",
    image: "assets/servicesImg/officeindoor.jpg",
    description:
      "AWG Trading & Distribution is a trusted wholesaler specializing in the trade of cut flowers, floral accessories, indoor plants, and decorative containers. With our extensive network and expertise, we ensure access to a wide range of high-quality stock and supplies that offer exceptional value to the UAE market. Our Corporate & Events Services go beyond trading and encompass a comprehensive Floral Advisory & Design Service tailored for Hotels, Resorts, Offices, and Special Events. From weddings and festivals to product launches and exhibitions, we provide expert guidance and exquisite designs to enhance the ambiance and beauty of these occasions.At AWG, we take pride in sourcing plants and flowers from around the world, infusing our surroundings with a harmonious blend of tranquility and elegance. As a one-stop shop, we excel at capturing and creating visually captivating experiences for weddings, events, offices, and homes, igniting the imagination and pleasing the eye.Whether you seek to incorporate the enchanting touch of nature into your business or function, our friendly and experienced staff is dedicated to introducing you to the possibilities and ensuring your satisfaction.In addition to our floral trading and distribution services, AWG also offers a diverse range of other products. Through our worldwide connections spanning Europe, America, Africa, the Far East, and beyond, we deliver the highest quality offerings in the market while accommodating project timelines and budgetary requirements.",
  },
  {
    id: 8,
    name: "Ground, Landscape & Irrigation Operations & Maintenance Urban Furniture",
    image: "assets/servicesImg/ground.jpg",
    description:
      "AWG specializes in Green Asset Management, offering comprehensive care and maintenance services for both new and established living landscapes. Our dedicated team of professionals, adhering to international benchmarks and industry best practices, ensures the systematic and monitored execution of operations and procedures.We provide a complete range of services for various infrastructure assets, including parks, gardens, resorts, high-end residential projects, and sporting facilities. ",
  },
  {
    id: 9,
    name: "Landscape Commercial & Residential Installation",
    image: "assets/servicesImg/landscapeschool.jpg",
    description:
      "At AWG, we understand the importance of landscape commercial and residential installation in the construction project planning process. As a leading expert in the field, we specialize in seamlessly integrating landscaping into projects, ensuring that they align with the unique goals and requirements of our clients. Collaborating closely with project owners, our team strives to deliver exceptional results that exceed expectations and enhance the overall project experience. From concept to execution, we are dedicated to transforming spaces into stunning landscapes that leave a lasting impression.",
  },
  {
    id: 10,
    name: "Professional Project Management",
    image: "assets/servicesImg/pointing-sketch.jpg",
    description:
      "AWG has developed an internal work instruction for Project Management, which serves as a comprehensive reference and guide for all employees involved in our projects.These work instructions provide detailed information about AWGs current Project Management practices and ensure consistency in the workflow of both our operational department and company projects.Our team strictly adheres to the five process groups and ten knowledge areas outlined by the Project Management Institute (PMI).",
  },
  {
    id: 11,
    name: "Shade Structure Fabrication, Supply And Installation",
    image: "assets/servicesImg/shades.jpeg",
    description:
      "The combination of elegance, style, and advanced engineering expertise defines AWGs proficiency in designing, manufacturing, and installing tension membrane structures. Our solutions integrate seamlessly into contemporary developments, surpassing international standards. With a focus on top-quality fabric materials like PTFE, PVC, and HDPE, our team tailors each application to perfection, supported by extensive design and material warranties.In addition to our specialization in tension membrane structures, AWG offers expert structural design services for commercial, residential, industrial, educational, and municipal facilities. Utilizing advanced computerized detailing software, including design data, AutoCAD, SolidWorks, and SATAD Pro, alongside customized software products, we ensure precise and efficient project completion. Our track record showcases our ability to successfully deliver even the most complex jobs within specified timelines and at competitive prices.Complementing our shade structure expertise, AWG also provides comprehensive lighting solutions. From the initial concept design to thorough system testing and commissioning, our team applies innovative techniques to optimize energy efficiency. Emphasizing the use of solar power, low voltage fixtures, LED technology, and energy-efficient lamp bulbs such as xenon and fiber optics, we stay at the forefront of lighting advancements, enhancing sustainability and reducing environmental impact.",
  },
  {
    id: 12,
    name: "Supply Of Tiles, Vinyl Flooring, Outdoor Lighting &Amp; Urban Furniture",
    image: "assets/servicesImg/tiles.jpg",
    description:
      "AWG is renowned for its global network and extensive partnerships across Europe, America, Africa, and the Far East, enabling us to deliver the finest quality products in the market while accommodating specific timelines and budgetary requirements.",
  },
  {
    id: 13,
    name: "Design & Installation Of Stamped Concrete & Decorative Asphalt",
    image: "assets/servicesImg/stampedConcrete.jpg",
    description:
      "AWG specializes in the design and installation of stamped concrete and decorative asphalt, offering a comprehensive range of products and services to enhance asphalt surfaces. As the exclusive agent for the Middle East & North Africa (MENA) region, we collaborate with Quest Construction Products from the USA, providing specialist coating systems for asphalt and pavement (visit www.streetbond.com). Additionally, we act as an agent for decorative stamped asphalt and patterned paving products based on the Integrated Paving Concepts technology from Canada and the USA (visit www.streetprint.com).At AWG, we are dedicated to delivering innovative, durable, and aesthetically pleasing solutions for decorative asphalt and concrete pavement construction. Our portfolio showcases a proprietary state-of-the-art technology that surpasses traditional brick-and-stamped concrete options, offering superior economic and performance benefits. We pride ourselves on using proven product solutions to create surfaces that are both visually appealing and long-lasting.",
  },
];
var initialized = false;

const ItemList = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (!initialized) {
      // call api or anything
      const urlParams = new URLSearchParams(window.location.search);
      const id = parseInt(urlParams.get('id'));
      const found = itemList.findIndex(item => {
        return item.id === id;
      })
      setSelectedItem(itemList[found])
      initialized =true;
    }


  });


  // useEffect(() => {
  //   setSelectedItem(itemList.find(item => item.id === id));
  // }, []);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <div className="flex scroll-smooth">
      <div className="sm:w-1/3 p-4  w-full md:block hidden">
        {/* <h1 className="text-2xl font-bold">Item List</h1> */}
        <ul className="space-y-1">
          {itemList.map((item) => (
            <li
              key={item.id}
              className={`cursor-pointer  p-2 ${selectedItem === item
                  ? "service-categories-selected"
                  : "service-categories-not-selected"
                }`}
              onClick={() => handleItemClick(item)}
            >
              {item.name}
            </li>
          ))}
        </ul>
      </div>

      <div className="md:w-2/3 hidden sm:block p-4">
        {selectedItem && (
          <h1 className="heading-title p-2">{selectedItem.name}</h1>
        )}
        <div>
          {selectedItem && (
            <img
              src={`${selectedItem.image}`}
              alt="image"
              className=" h-[300px] w-full rounded-[20px]"
            />
          )}
        </div>

        {selectedItem && (
          <p className="mt-3  p-2">{selectedItem.description}</p>
        )}
      </div>

      <div className="w-full   sm:hidden">
        <ListWithDropdown items={itemList} />
      </div>



      {/* <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 md:hidden ">
        <div className="lg:col-span-2">
          <ul className="space-y-4">
            {itemList.map((item) => (
              <li
                key={item.id}
                className="bg-white rounded shadow-md p-4 cursor-pointer hover:bg-gray-100"
                onClick={() => handleItemClick(item)}
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>
        <div className="lg:col-span-1">
          {selectedItem && (
            <div className="bg-white rounded shadow-md p-4">
              <h2 className="text-xl font-bold">{selectedItem.name}</h2>
              <p>{selectedItem.description}</p>
             
            </div>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default ItemList;
