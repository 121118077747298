// src/Navbar.js
import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { FaReact, FaBars } from 'react-icons/fa';


const Navbar = () => {
  // State to keep track of the selected menu item
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  
  return (
    <div className='w-full h-[100px]'>
      <nav className=" flex justify-between item-centre mx-auto  ">

<div className='flex'>
  <a href='/' className='flex '>
    <img className="Logo flex-1 w-20 md:ml-10 mt-0" src="AWG logo hd.jpg" />
    <span className="CompanyName flex-2 mt-10 text-lime-600 text-2xl font-medium font-['Dubai']">Agricultural World Gardens</span>
  </a>
</div>

<div className=" invisible md:visible md:static absolute bg-white md:width-auto justify-end px-10  flex-1  flex items-center md:pb-0 md:items-center 
 ${isOpen ? 'top-[-100%]' : 'top-[20%]'}">

  <ul className={'flex md:flex-row md:width-auto flex-col md:min-h-fit  md:items-center md:gap-8 gap gap-8 transition-all duration-500 ease-in  '}>

    <li className={location.pathname === '/' ? "text-lime-500 text-sm font-bold font-['Dubai'] " : "text-neutral-500 text-sm font-medium font-['Dubai'] hover:text-lime-600 hover:-translate-y-1 transition ease-in-out delay-100"}>
      <Link to="/">Home</Link>
    </li>

    <li className={location.pathname === '/aboutus' ? "text-lime-500 text-sm font-bold font-['Dubai']" : "text-neutral-500 text-sm font-medium font-['Dubai'] hover:text-lime-600 hover:-translate-y-1 transition ease-in-out delay-100"}>
      <Link to="/aboutus">About Us</Link>
    </li>

    <li className={location.pathname === '/services' ? "text-lime-500 text-sm font-bold font-['Dubai']" : "text-neutral-500 text-sm font-medium font-['Dubai'] hover:text-lime-600 hover:-translate-y-1 transition ease-in-out delay-100"}>
      <Link to="/services">Our Services</Link>
    </li>

    <li className={location.pathname === '/portfolio' ? "text-lime-500 text-sm font-bold font-['Dubai']" : "text-neutral-500 text-sm font-medium font-['Dubai'] hover:text-lime-600 hover:-translate-y-1 transition ease-in-out delay-100"}>
      <Link to="/portfolio">Our Portfolio</Link>
    </li>


    <li className={location.pathname === '/clients' ? "text-lime-500 text-sm font-bold font-['Dubai']" : "text-neutral-500 text-sm font-medium font-['Dubai'] hover:text-lime-600 hover:-translate-y-1 transition ease-in-out delay-100"}>
      <Link to="/clients">Our Clients</Link>
    </li>

    <li className={location.pathname === '/contact' ? "text-lime-500 text-sm font-bold font-['Dubai']" : "text-neutral-500 text-sm font-medium font-['Dubai'] hover:text-lime-600 hover:-translate-y-1 transition ease-in-out delay-100"}>
      <Link to="/contact">Contact Us</Link>
    </li>

    <li>
      <div className='navicon flex-auto hover:-translate-y-1 transition ease-in-out delay-100 '>
        <a href='https://www.linkedin.com/company/agriculture-world-gardens/?viewAsMember=true' className='hover:-translate-y-1 transition ease-in-out delay-100'>
          <svg className='hover:text-lime-600' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#777777" d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14m-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93h2.79M6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37h2.77Z" /></svg>
        </a>
      </div>
    </li>
    <li>
      <div className='navicon flex-auto hover:-translate-y-1 transition ease-in-out delay-100  '>
        <a href='https://instagram.com/agriculturalworldgardens?igshid=MzRlODBiNWFlZA=='>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#777777" d="M13.028 2.001a78.82 78.82 0 0 1 2.189.022l.194.007c.224.008.445.018.712.03c1.064.05 1.79.218 2.427.465c.66.254 1.216.598 1.772 1.154a4.908 4.908 0 0 1 1.153 1.771c.247.637.415 1.364.465 2.428c.012.266.022.488.03.712l.006.194a79 79 0 0 1 .023 2.188l.001.746v1.31a78.836 78.836 0 0 1-.023 2.189l-.006.194c-.008.224-.018.445-.03.712c-.05 1.064-.22 1.79-.466 2.427a4.884 4.884 0 0 1-1.153 1.772a4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.427.465c-.267.012-.488.022-.712.03l-.194.006a79 79 0 0 1-2.189.023l-.746.001h-1.309a78.836 78.836 0 0 1-2.189-.023l-.194-.006a60.64 60.64 0 0 1-.712-.03c-1.064-.05-1.79-.22-2.428-.466a4.89 4.89 0 0 1-1.771-1.153a4.904 4.904 0 0 1-1.154-1.772c-.247-.637-.415-1.363-.465-2.427a74.367 74.367 0 0 1-.03-.712l-.005-.194A79.053 79.053 0 0 1 2 13.028v-2.056a78.82 78.82 0 0 1 .022-2.188l.007-.194c.008-.224.018-.446.03-.712c.05-1.065.218-1.79.465-2.428A4.88 4.88 0 0 1 3.68 3.68a4.897 4.897 0 0 1 1.77-1.155c.638-.247 1.363-.415 2.428-.465l.712-.03l.194-.005A79.053 79.053 0 0 1 10.972 2h2.056Zm-1.028 5A5 5 0 1 0 12 17a5 5 0 0 0 0-10Zm0 2A3 3 0 1 1 12.001 15a3 3 0 0 1 0-6Zm5.25-3.5a1.25 1.25 0 0 0 0 2.498a1.25 1.25 0 0 0 0-2.5Z" /></svg>
        </a>
      </div>
    </li>

  </ul>

</div>
{ isOpen ?
  <div className=" md:hidden z-10 p-10 absolute bg-white md:width-auto   flex items-center md:pb-0 md:items-center 
 top-[15%] right-0">

   <ul className={'flex md:flex-row md:width-auto flex-col md:min-h-fit  md:items-center md:gap-8 gap gap-8 transition-all duration-500 ease-in  '}>

     <li className={location.pathname === '/' ? "text-lime-500 text-sm font-bold font-['Dubai'] " : "text-neutral-500 text-sm font-medium font-['Dubai'] hover:text-lime-600"}>
       <Link onClick={()=>setIsOpen(false)}  to="/">Home</Link>
     </li>

     <li className={location.pathname === '/aboutus' ? "text-lime-500 text-sm font-bold font-['Dubai']" : "text-neutral-500 text-sm font-medium font-['Dubai'] hover:text-lime-600"}>
       <Link onClick={()=>setIsOpen(false)}  to="/aboutus">About Us</Link>
     </li>

     <li className={location.pathname === '/services' ? "text-lime-500 text-sm font-bold font-['Dubai']" : "text-neutral-500 text-sm font-medium font-['Dubai'] hover:text-lime-600"}>
       <Link onClick={()=>setIsOpen(false)}  to="/services">Our Services</Link>
     </li>

     <li className={location.pathname === '/portfolio' ? "text-lime-500 text-sm font-bold font-['Dubai']" : "text-neutral-500 text-sm font-medium font-['Dubai'] hover:text-lime-600"}>
       <Link onClick={()=>setIsOpen(false)} to="/portfolio">Our Portfolio</Link>
     </li>


     <li className={location.pathname === '/clients' ? "text-lime-500 text-sm font-bold font-['Dubai']" : "text-neutral-500 text-sm font-medium font-['Dubai'] hover:text-lime-600"}>
       <Link onClick={()=>setIsOpen(false)}  to="/clients">Our Clients</Link>
     </li>

     <li className={location.pathname === '/contact' ? "text-lime-500 text-sm font-bold font-['Dubai']" : "text-neutral-500 text-sm font-medium font-['Dubai'] hover:text-lime-600"}>
       <Link  onClick={()=>setIsOpen(false)}  to="/contact">Contact Us</Link>
     </li>

     

   </ul>

 </div>
 :<div></div>
}
<div className='flex items-center gap-6'>

  <button className='md:hidden sm:text-3xl' onClick={() => setIsOpen(!isOpen)}>
 {
  isOpen ?  <Icon icon="iconamoon:close" color="#86c23a" className='text-3xl'  /> : <Icon icon="ep:menu" color="#86c23a" className='text-3xl' />
 }

  </button>
  



  
</div>




</nav>
    </div>
  );
}

export default Navbar;
