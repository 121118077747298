import React, { useState } from 'react';


const ContactForm = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_phone: '',
    from_email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://europe-west1-awg-admin.cloudfunctions.net/api/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
       
        setFormData({
          from_name: '',
          from_phone: '',
          from_email: '',
          message: '',
        });
        alert('Email sent successfully!');

      } else {
        alert('Email failed to send.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('An error occurred while sending the email.');
    }
  };

  return (
    <div className="max-w-lg mx-auto">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          
          <input
            placeholder='Name'
            type="text"
            id="name"
            name="from_name"
            value={formData.from_name}
            onChange={handleChange}
            className=" w-full rounded-lg border border-neutral-500 py-2 px-3"
            required
          />
        </div>
        <div>
          
          <input
            placeholder='Phone'
            type="text"
            id="name"
            name="from_phone"
            value={formData.from_phone}
            onChange={handleChange}
            className=" w-full rounded-lg border border-neutral-500 py-2 px-3"
            required
          />
        </div>
        <div>
         
          <input
            placeholder='E-mail'
            type="email"
            id="email"
            name="from_email"
            value={formData.from_email}
            onChange={handleChange}
            className=" w-full rounded-lg border border-neutral-500 py-2 px-3"
            required
          />
        </div>
        <div>
          
          <textarea
            placeholder='Message'
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className=" w-full rounded-lg border border-neutral-500 py-2 px-3 h-32"
            required
          />
        </div>
        <div>
          <button
            type="submit"
            className="home-button mb-5 sm:mb-0 py-2 px-4 w-full rounded-lg "
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm